import React, { useMemo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import Cookies from "universal-cookie";
// Controls
import Btn from "../Controls/Button/Button";
// Custom Hooks
import usePriceSummary from "../../CustomHooks/usePriceSummary";
import useCheckoutForm from "../../CustomHooks/useCheckoutForm";
// Redux actions
import { clearOrder } from "../../store/Order/OrderActions";
import {
  addGroupRefIdWithMsg,
  showSnackbarAction,
} from "../../store/Snackbar/SnackbarAction";
//Utils
import {
  CURRENCY,
  ERROR_TYPES,
  MESSAGES,
  PAYMENT_METHODS,
  ORDER_TYPES,
} from "./../../utils/Constants";
import { useCookies } from "react-cookie";
import { setLocalStorageItem } from "../../utils/utils";
import moment, { invalid } from "moment";
import { dispatchErrorMessageAsDialog } from "../../utils/Shared";
import {
  setError,
  updateAddressAccordian,
  updateContactAccordian,
  updateNewAddressForm,
  updatePaymentAccordian,
} from "../../store/Checkout/CheckoutAction";
import SpecialAlert from "../SpecialAlert/SpecialAlert";
import useWalletPoints from "../../CustomHooks/useWalletPoints";
import useSortHook from "../../CustomHooks/useSortHook";
import {
  clearAvailableCoupons,
  clearCoupon,
  clearDiscountType,
  deleteFromCart,
  setWalletRedeemed,
} from "../../store/Cart/CartActions";
import {
  clearUsedWalletPoints,
  resetSelectedWallets,
  resetWallet,
} from "../../store/Profile/ProfileActions";
// const cookies = new Cookies();

// function cookieSet(key, val) {
//   return new Promise((resolve, reject) => {
//     cookies.addChangeListener((res) => {
//       // console.log('res', res)
//       cookies.removeChangeListener();
//       resolve();
//     })
//     cookies.set(key, val)
//   })
//  }

function PaymentButton({ mobileButton, className, paymentName }) {
  const [cookies, setCookie] = useCookies([
    "orderId",
    "payementId",
    "hotelKey",
    "paymentMethod",
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderInProcess, orderResponse } = useSelector((state) => state.order);
  const { duration, locationState, currentDeliveryZone } = useSelector(
    (state) => state.location
  );
  const { isChecked } = useSelector((state) => state.checkout);
  const { voucherData } = useSelector((state) => state.voucher);
  const { hotelKey, tableId } = useSelector((state) => state.hotel);

  const {
    selectedTable,
    isWalletRedeemed,
    selectedCoupon,
    data,
    tipAmount,
    checkoutDetails,
  } = useSelector((state) => state.cart);

  const { orderButtonText, inValid, payment, formValues } = useCheckoutForm();
  const priceSummary = usePriceSummary();
  const [isAlertModal, setIsAlertModal] = useState(false);
  const { hotel } = useSelector((state) => state.hotel);
  const [modalTitle, setModalTitle] = useState("");
  const { checkWalletPointsConditions } = useWalletPoints();
  const {
    checkComboCatAvailability,
    checkProductAvailability,
    formatDateToCheck,
  } = useSortHook();
  const [availbilityModal, setAvailabilityModal] = useState({
    isOpen: false,
    text: null,
  });
  const { category, comboCategories } = useSelector((state) => state.menu);
  const { resetWalletProducts, RemoveItemCoupon } = useWalletPoints();

  const [nonAvailableProducts, setNonAvailiableProducts] = useState([]);
  // console.log("payment name", formValues);
  // console.log("priceSummary", priceSummary);

  // console.log("isChecked", isChecked);
  const [currentTime, setCurrentTime] = useState(moment()); // Initialize current time using moment

  // Update current time every second using useEffect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const getButtonText = useMemo(() => {
    let total = voucherData?.value
      ? parseFloat(priceSummary?.totalAfterAppliedVoucher)
      : parseFloat(priceSummary?.total);

    if (tipAmount) {
      total += parseFloat(tipAmount);
    }

    if (mobileButton) {
      return `${orderButtonText}`;
    }

    if (voucherData?.value || tipAmount) {
      return `${orderButtonText} ${CURRENCY.SIGN}${total.toFixed(2)}`;
    } else if (priceSummary?.total === "0.00") {
      return `Completed Purchase`;
    } else {
      return `${orderButtonText} ${CURRENCY.SIGN}${total.toFixed(2)}`;
    }
  }, [mobileButton, orderButtonText, priceSummary, voucherData, tipAmount]);

  const getButtonProcessingtext = useMemo(() => {
    if (formValues.paymentMethod === PAYMENT_METHODS.ONLINE_PAYMENT) {
      return `Processing payment...`;
    } else {
      return `Sending orders...`;
    }
  }, [formValues]);

  // console.log("first condition", priceSummary?.totalWithoutDiscount + currentDeliveryZone?.minimum_amount)
  // console.log("second condition", formValues?.customer_name)
  // console.log("third condition", formValues?.customer_address)
  // console.log("formvaluessssss", formValues);
  // console.log("tableiddddd", tableId);
  // console.log("formValues?.customer_name", formValues);

  const handleAlertModal = () => {
    if (
      hotel?.is_checkout_alert_delivery === 1 &&
      hotel?.checkout_alert_delivery != null &&
      formValues?.orderType === "DELIVERY"
    ) {
      setModalTitle(hotel?.checkout_alert_delivery);
      setIsAlertModal(true);
    } else if (
      hotel?.is_checkout_alert_pickup === 1 &&
      hotel?.checkout_alert_pickup != null &&
      formValues?.orderType === "PICKUP"
    ) {
      setModalTitle(hotel?.checkout_alert_pickup);
      setIsAlertModal(true);
    } else {
      setIsAlertModal(false);
      handlePaymentBtn();
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date?.toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    });
  };

  const filteredComboCategory = (catId) => {
    return comboCategories?.find((cat) => cat?.combo_cat_id == catId);
  };

  const filteredProdCategory = (catId) => {
    return category?.find((cat) => cat?.categoryId == catId);
  };

  const isProductsAvailable = (data) => {
    let productNameArray = [];
    let unavailableProducts = [];
    let status = true;

    data?.forEach((product) => {
      let isAvailable = false;

      // Check if it's a combo
      if (product?.combo_id) {
        const comboCategory = comboCategories.find(
          (c) => c.combo_cat_id === product.combo_cat_id
        );

        // Check both combo category and product availability
        isAvailable =
          checkComboCatAvailability(
            comboCategory,
            checkoutDetails?.deliveryType === "ASAP"
              ? currentTime.toDate()
              : formatDateToCheck(checkoutDetails?.deliveryTime)
          ) &&
          checkProductAvailability(
            product,
            checkoutDetails?.deliveryType === "ASAP"
              ? currentTime.toDate()
              : formatDateToCheck(checkoutDetails?.deliveryTime)
          );
      } else {
        // Check product and product category availability
        isAvailable =
          checkProductAvailability(
            product,
            checkoutDetails?.deliveryType === "ASAP"
              ? currentTime.toDate()
              : formatDateToCheck(checkoutDetails?.deliveryTime)
          ) &&
          checkProductAvailability(
            category.find((c) => c.categoryId === product.categoryId),
            checkoutDetails?.deliveryType === "ASAP"
              ? currentTime.toDate()
              : formatDateToCheck(checkoutDetails?.deliveryTime)
          );
      }

      // If not available, handle unavailable products
      if (!isAvailable) {
        productNameArray.push(
          product?.combo_id ? product?.combo_name : product?.label
        );
        status = false;
        unavailableProducts.push(product); // Collect all unavailable products
      }
    });

    // Update state once with the full list of unavailable products
    setNonAvailiableProducts(unavailableProducts);

    return { productNameArray, status };
  };

  const handleCloseAvailibilityForTimeChange = () => {
    setAvailabilityModal({ isOpen: false, text: null });
  };

  const handleCloseAvailibilityForRemove = () => {
    // console.log("nonAvailableProducts", nonAvailableProducts);

    // Ensure that the array is iterated and each product is removed
    if (nonAvailableProducts?.length > 0) {
      nonAvailableProducts.forEach((prod) => {
        dispatch(deleteFromCart(prod)); // Remove each product from cart
      });

      // Clear wallet points and coupon information
      dispatch(clearUsedWalletPoints());

      // Check if a coupon is applied and remove it
      if (selectedCoupon?.id) {
        RemoveItemCoupon(selectedCoupon);
      }
      dispatch(clearCoupon());
      resetWalletProducts(1);

      // Close the modal and redirect to cart
      setAvailabilityModal({ isOpen: false, text: null });
      history.push(`/${hotelKey}/cart`);
    } else {
      console.error("No unavailable products to remove.");
    }
  };

  const availbilityModalText = (unavailableProducts) => {
    if (unavailableProducts?.length === 0) {
      return "All products are available for the selected time.";
    }

    const productNames = unavailableProducts.join(", ");
    return `Sorry, these items are not available for the selected time: ${productNames}`;
  };

  const handlePaymentBtn = () => {
    setIsAlertModal(false);
    if (!isChecked) {
      dispatch(setError(true));
      setTimeout(() => {
        dispatch(setError(false));
      }, 500);
      return;
    } else if (!isProductsAvailable(data).status) {
      setAvailabilityModal({
        isOpen: true,
        text: availbilityModalText(isProductsAvailable(data).productNameArray),
      });
      return;
    } else if (
      priceSummary?.totalWithoutDiscount <
        currentDeliveryZone?.minimum_amount &&
      formValues?.orderType == ORDER_TYPES.DELIVERY
    ) {
      dispatchErrorMessageAsDialog({
        type: ERROR_TYPES.MIN_AMOUNT_ORDER,
        message: `${MESSAGES.INVALID_TOTAL_AMOUNT} ${CURRENCY.SIGN}${currentDeliveryZone?.minimum_amount}`,
      });
    } else if (!tableId && !formValues?.customer_name) {
      dispatch(updateContactAccordian(true));
      dispatch(showSnackbarAction("Please enter customer name", "warning"));
      dispatch(addGroupRefIdWithMsg("contactForm", "Please enter username"));
    } else if (!tableId && !formValues?.customer_phone) {
      dispatch(updateContactAccordian(true));
      dispatch(
        showSnackbarAction("Please enter your valid Phone number", "warning")
      );
      dispatch(
        addGroupRefIdWithMsg(
          "contactForm",
          "Please enter your valid Phone number"
        )
      );
    } else if (!tableId && !formValues?.customer_email) {
      dispatch(updateContactAccordian(true));
      dispatch(showSnackbarAction("Please enter your valid Email", "warning"));
      dispatch(
        addGroupRefIdWithMsg("contactForm", "Please enter your valid Email")
      );
    } else if (
      formValues?.orderType === "DELIVERY" &&
      !formValues?.customer_address
    ) {
      // console.log("calling formvalues of customer address", formValues?.customer_address)
      dispatch(
        addGroupRefIdWithMsg("addressForm", "Please enter your Address")
      );
      dispatch(updateAddressAccordian(true));
      updateNewAddressForm(true);
      dispatch(showSnackbarAction("Please enter your address", "warning"));
    } else if (
      formValues.payment_gateway === undefined ||
      "" ||
      null ||
      !formValues.payment_gateway
    ) {
      dispatch(updatePaymentAccordian(true));
      dispatch(showSnackbarAction("Please Choose a payment method", "warning"));
      dispatch(
        addGroupRefIdWithMsg(
          "paymentMethodForm",
          "Please Select any payment method"
        )
      );
    } else if (isWalletRedeemed) {
      const { status, message } = checkWalletPointsConditions();

      if (!status) {
        dispatch(showSnackbarAction(message, "warning"));
        return;
      }
    }

    // dispatch(addGroupRefIdWithMsg(null, null));
    // console.log("calling payment function");
    payment();
  };
  // console.log("orderrrrrrr statusssssss",orderInProcess)
  // const handleTablePayment = () => {
  //   dispatch(setTablePeopleCount(data?.count));
  //   setOpen(false);
  //   payment();
  // };

  // console.log("orderResponse", orderResponse)

  useEffect(() => {
    async function fetchData() {
      if (orderResponse) {
        setCookie("hotelKey", hotelKey, { path: "/" });
        setCookie("paymentMethod", formValues.paymentMethod, { path: "/" });
        setCookie("orderId", orderResponse.order_id, { path: "/" });
        setCookie("paymentId", orderResponse.payment_id, { path: "/" });
        setLocalStorageItem("orderId", orderResponse.order_id);
        setLocalStorageItem("hotelKey", hotelKey);
        setLocalStorageItem("paymentMethod", formValues.paymentMethod);
        setLocalStorageItem("paymentId", orderResponse.payment_id);
        if (formValues.paymentMethod === PAYMENT_METHODS.ONLINE_PAYMENT) {
          // console.log("orderResponse.payment_url", orderResponse.payment_url)
          const paymentUrl = orderResponse.payment_url;
          if (paymentUrl) {
            dispatch(clearOrder());
            setTimeout(() => {
              window.location.href = paymentUrl;
            }, 500);
          } else {
            dispatch(
              showSnackbarAction(
                "Something wrong in payment gatway, Please try again after some time",
                "warning"
              )
            );
          }
        } else {
          history.push(`/payment-callback`);
          // history.push(`/orders/status`)
        }
      }
    }
    fetchData();
  }, [orderResponse, dispatch, formValues.paymentMethod, history, hotelKey]);

  const handleForceClose = () => {
    setModalTitle("");
    setIsAlertModal(false);
  };

  return (
    <>
      <Btn
        // disabled={inValid || orderInProcess || (formValues.orderType === ORDER_TYPES.DELIVERY ? !duration : formValues.orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING ? !selectedTable?.id : false)}
        handleClick={handleAlertModal}
        text={orderInProcess ? getButtonProcessingtext : getButtonText}
        className={className}
        disabled={orderInProcess}
      />
      <SpecialAlert
        title={modalTitle}
        open={isAlertModal}
        handleClose={handlePaymentBtn}
        handleForceClose={handleForceClose}
        isCheckout={true}
      />
      <SpecialAlert
        open={availbilityModal.isOpen}
        title={availbilityModal.text}
        handleForceClose={handleCloseAvailibilityForRemove}
        handleClose={handleCloseAvailibilityForTimeChange}
        isAvailibilityModal={true}
        isCheckout={true}
      />
    </>
  );
}

export default PaymentButton;

//   if (paymentUrl !== previoudPaymentUrl) {
//     setIsPaymentProcessing(false);
//     setPreviousPaymentUrl(paymentUrl);
//     if (paymentUrl) {
//       dispatch(clearPaymentUrl());
//       window.location.href = paymentUrl;
//     }
//   }
