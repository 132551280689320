import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
// Custom Hooks
import useDynamicHotelData from "../../../CustomHooks/useDynamicHotelData";
// Styles
import useStyles from "./SelectOrderType.style.js";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DeliveryTime from "../DeliveryTime/DeliveryTime.js";
import DateSlotPicker from "../../Checkout/DateSlotPicker/index.js";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_TYPES } from "../../../utils/Constants.js";
import { generateDateSlots } from "../../../utils/utils.js";
import TimePicker from "../../Checkout/TimePicker/index.js";
import moment from "moment";
import { Info } from "@material-ui/icons";
import { clearTipAmount } from "../../../store/Cart/CartActions.js";

const SelectOrderType = ({
  value = null,
  onSelect = () => {},
  formValues,
  handleClose,
  onDateAndTimeSelect,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const [selectedOderType, setOrderType] = useState(value);
  const [dateSlots, setDateSlots] = useState([]);
  const [dateSlotsError, setDateSlotsError] = useState(false);
  const [date, setDate] = useState(null);
  const [typeOfOrder, setTypeOfOrder] = useState("SCHEDULED");
  const [time, setTime] = useState(null);
  const { theme } = useSelector((state) => state?.theme);
  const [orderTimeType, setOrderTimeType] = useState(null);
  const hasMounted = useRef(false);
  const {
    getOrderTypes,
    getDeliveryIntervalSlots,
    getTodayDeliveryIntervalSlots,
    isAsapAvailable,
    isMultipleOrderType,
    getAsapTime,
  } = useDynamicHotelData();
  const [newChange, setNewChange] = useState(false);

  // state which return is shedulued time have value or not
  const isScheduledAvailable = useMemo(() => {
    return (
      (Array.isArray(getTodayDeliveryIntervalSlots) &&
        getTodayDeliveryIntervalSlots.length > 0) ||
      (Array.isArray(getDeliveryIntervalSlots) &&
        getDeliveryIntervalSlots.length > 0)
    );
  }, [getTodayDeliveryIntervalSlots, getDeliveryIntervalSlots]);

  // function for ordertype selection
  const onOrderTypeSelect = (fieldName, type) => {
    setOrderType(type);
    onSelect(fieldName, type);
    dispatch(clearTipAmount());
  };

  // Select first order type if their is multiple ordertypes
  useEffect(() => {
    if (!formValues?.orderType && getOrderTypes?.length > 0) {
      onOrderTypeSelect("orderType", getOrderTypes[0].type);
    }
  }, [formValues?.orderType, getOrderTypes]);

  // switch to next orderType if, default selected ordertype has no asap and sheduled time
  useEffect(() => {
    if (formValues?.orderType && getOrderTypes?.length > 0) {
      const timeoutId = setTimeout(() => {
        // Find the current order type in the getOrderTypes array
        const currentOrderType = formValues?.orderType;

        // Find the type to switch to, based on the current orderType
        const nextOrderType = getOrderTypes.find(
          (typeObj) => typeObj.type !== currentOrderType
        );

        // If there's a valid type to switch to, update the orderType
        if (!isAsapAvailable && !isScheduledAvailable && nextOrderType) {
          onOrderTypeSelect("orderType", nextOrderType.type);
        }
      }, 1000); // 2-second timeout

      // Cleanup function to clear the timeout if dependencies change
      return () => clearTimeout(timeoutId);
    }
  }, [
    formValues?.orderType,
    getOrderTypes,
    isAsapAvailable,
    isScheduledAvailable,
  ]);

  // Select ordertimetype by default
  useEffect(() => {
    if (!newChange) {
      if (!hasMounted.current && formValues?.orderType) {
        // Initial render: Set default order time based on ASAP availability and order type
        const initialOrderTimeType =
          isAsapAvailable &&
          hotel?.openingAvailable &&
          (formValues?.orderType === "DELIVERY" ||
            formValues?.orderType === "PICKUP")
            ? "ASAP"
            : "SCHEDULED";

        setOrderTimeType(initialOrderTimeType);
        handleOrderTime(initialOrderTimeType);

        hasMounted.current = true; // Mark initial render as done
      } else {
        // On subsequent renders: Ensure correct default order time
        const shouldAsapBeDefault =
          isAsapAvailable &&
          hotel?.openingAvailable &&
          (formValues?.orderType === "DELIVERY" ||
            formValues?.orderType === "PICKUP");

        handleOrderTime(shouldAsapBeDefault ? "ASAP" : "SCHEDULED");
        setOrderTimeType(shouldAsapBeDefault ? "ASAP" : "SCHEDULED");
      }
    }
  }, [isAsapAvailable, formValues, hotel?.openingAvailable, newChange]);

  const generateDatePickerSlots = () => {
    let orderTypeValue = value;
    if (value === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING) {
      orderTypeValue = ORDER_TYPES.OPENING;
    }
    const dates = generateDateSlots(hotel, orderTypeValue) || [];
    setDateSlots(dates);
    if (!dates || !dates?.length) {
      setDateSlotsError(true);
    }
  };

  useEffect(() => {
    const timeString = `${date}${time ? ` ${time}` : ""}`;
    const dTime = moment(moment(timeString)).toISOString();
    onDateAndTimeSelect(typeOfOrder || "SCHEDULED", dTime);
  }, [date, time, typeOfOrder]);

  useEffect(() => {
    if (hotel || formValues?.orderType || value) {
      generateDatePickerSlots();
    }
  }, [hotel, formValues?.orderType, value]);

  const onClick = () => {
    setNewChange(true);
  };

  const onSelectDataAndTime = ({ value, typeOfOrder } = {}, type) => {
    if (!value) return;
    if (typeOfOrder) {
      setTypeOfOrder(typeOfOrder);
    }
    if (type === "date") {
      // Set the selected date
      setDate(value);

      // Automatically select the first available time slot for the new date
      const availableTimeSlots = getAvailableTimeSlotsForDate(value);
      if (availableTimeSlots && availableTimeSlots.length > 0) {
        const firstTimeSlot = availableTimeSlots[0].value; // Assuming time slot object has a 'value' property
        setTime(firstTimeSlot); // Set the first available time
      }
    }

    if (type === "time") {
      setTime(value); // Set time manually if time is selected
    }
  };

  // Function to get the available time slots for a given date
  const getAvailableTimeSlotsForDate = (selectedDate) => {
    // Get the time slots for today or another date based on the selected date
    const availableSlots = moment(selectedDate).isSame(moment(), "date")
      ? getTodayDeliveryIntervalSlots
      : getDeliveryIntervalSlots;

    // Add condition to filter for "SCHEDULED" time slots if orderTimeType is "SCHEDULED"
    if (orderTimeType === "SCHEDULED") {
      return availableSlots.filter((slot) => slot.typeOfOrder === "SCHEDULED");
    }

    return availableSlots; // Return all available slots if not filtered
  };

  const getLabel = (arry) => {
    const asapSlot = arry.find((item) => item.typeOfOrder === "ASAP");
    return asapSlot ? asapSlot.label : undefined;
  };

  const filteredOrderTime = (array) => {
    const result = array.find((arr) => arr?.typeOfOrder === "ASAP");

    return result;
  };

  const filteredSheduledTimes = (array) => {
    const result = array.find((arr) => arr?.typeOfOrder === "SCHEDULED");

    return result;
  };

  const handleOrderTime = (e) => {
    setOrderTimeType(e);
    if (e === "ASAP") {
      const filteredTime = filteredOrderTime(
        getTodayDeliveryIntervalSlots?.length > 0
          ? getTodayDeliveryIntervalSlots
          : getDeliveryIntervalSlots
      );
      onSelectDataAndTime(filteredTime, "ASAP");
      onSelectDataAndTime(filteredTime, "time");
    } else if (e === "SCHEDULED") {
      const filteredTime = filteredSheduledTimes(
        getTodayDeliveryIntervalSlots?.length > 0
          ? getTodayDeliveryIntervalSlots
          : getDeliveryIntervalSlots
      );
      onSelectDataAndTime(filteredTime, "SCHEDULED");
      onSelectDataAndTime(filteredTime, "time");
    }
  };

  const getDeliveryType = useMemo(() => {
    return selectedOderType;
  }, [selectedOderType]);

  const getLastTime = (array) => {
    if (!array || array.length === 0) {
      return undefined;
    }

    const lastElement = array[array.length - 1];

    return lastElement?.label;
  };

  return (
    <>
      {/* <div className={classes.headerDiv}>
        {theme?.header_logo ? (
          <div className={classes.headerLogoDiv}>
            <img
              src={`data:image/png;base64, ${theme?.header_logo}`}
              alt="Header Logo"
              className={classes.headerLogo}
            />
          </div>
        ) : (
          <Typography
            className={clsx(classes.title)}
            variant="h1"
            display="block"
          >
            {hotel ? hotel.hotelName : ""}
          </Typography>
        )}
      </div> */}
      {/* {isMultipleOrderType && ( */}
      <div className={classes.modalTitleSection}>
        <Typography
          className={classes.modalTitle}
          variant="h1"
          align="center"
          paragraph
        >
          {"Select Order Type"}
        </Typography>
        <Divider />
      </div>
      {/* )} */}
      {/* {isMultipleOrderType && ( */}
      <div id="checkout-orderTypeForm" className={clsx(classes.container)}>
        {getOrderTypes?.map((o, i) => (
          <div
            key={i}
            className={clsx(
              classes.btnNormal,
              o.type === selectedOderType && classes.item
              // o.active === false && classes.itemDisabled
            )}
            onClick={() => {
              onOrderTypeSelect("orderType", o.type);
              setNewChange(false);
            }}
          >
            <div className={classes.itemDiv}>
              <o.Icon
                className={clsx(
                  o.type === selectedOderType
                    ? classes.itemIcon
                    : classes.normalIcon
                )}
              />
            </div>
            <Typography
              // color="primary"
              className={clsx(
                o.type === selectedOderType
                  ? classes.itemText
                  : classes.normalText
              )}
              variant="caption"
            >
              {o.title}
            </Typography>
          </div>
        ))}
      </div>
      {/* )} */}
      <div className={classes.modalTitleSection} style={{ marginTop: "16px" }}>
        <Typography
          className={classes.modalTitle}
          variant="h1"
          align="center"
          paragraph
        >
          {"Order Time"}
        </Typography>
        <Divider />
      </div>
      <div className={classes.timeSelectDiv}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={orderTimeType}
            name="radio-buttons-group"
            onChange={(e) => handleOrderTime(e.target.value)}
            onClick={onClick}
          >
            <FormControlLabel
              value="ASAP"
              className={classes.radioLabel}
              control={
                <Radio
                  className={classes.radioBtn}
                  classes={{ checked: classes.checked }}
                />
              }
              label={
                !isAsapAvailable ||
                !hotel?.openingAvailable ||
                !getLabel(
                  getTodayDeliveryIntervalSlots.length > 0
                    ? getTodayDeliveryIntervalSlots
                    : getDeliveryIntervalSlots
                )
                  ? "ASAP : Unavailable"
                  : `ASAP : order in ${getLabel(
                      getTodayDeliveryIntervalSlots.length > 0
                        ? getTodayDeliveryIntervalSlots
                        : getDeliveryIntervalSlots
                    )}`
              }
              checked={orderTimeType === "ASAP"}
              disabled={
                !isAsapAvailable ||
                !hotel?.openingAvailable ||
                !getLabel(
                  getTodayDeliveryIntervalSlots.length > 0
                    ? getTodayDeliveryIntervalSlots
                    : getDeliveryIntervalSlots
                )
              }
            />
            <FormControlLabel
              value="SCHEDULED"
              className={classes.radioLabel}
              control={
                <Radio
                  className={classes.radioBtn}
                  classes={{ checked: classes.checked }}
                />
              }
              label="Sheduled for later"
              checked={orderTimeType === "SCHEDULED"}
              // onChange={(e) => handleOrderTime(e.target.value)}
            />
          </RadioGroup>
        </FormControl>
        <div
          id="checkout-orderTypeForm"
          className={clsx(
            orderTimeType === "SCHEDULED"
              ? classes.sheduledContainer
              : classes.hideContainer
          )}
        >
          <DateSlotPicker
            slots={dateSlots}
            className={`w-100 ${classes.dateTimeSelection}`}
            onChange={onSelectDataAndTime}
          />
          <TimePicker
            slots={
              moment(formValues.deliveryTime).isSame(moment(), "date") &&
              // filteredTimeSlots(
              getTodayDeliveryIntervalSlots.length > 0
                ? getTodayDeliveryIntervalSlots
                : getDeliveryIntervalSlots
              // )
            }
            shouldFilter={true}
            date={date}
            onClick={onClick}
            orderTimeType={orderTimeType}
            className={`w-100 ${classes.dateTimeSelection}`}
            onChange={onSelectDataAndTime}
          />
        </div>

        {getLastTime(
          getTodayDeliveryIntervalSlots.length > 0
            ? getTodayDeliveryIntervalSlots
            : getDeliveryIntervalSlots
        ) &&
          formValues?.deliveryType === "ASAP" &&
          getDeliveryType &&
          isAsapAvailable &&
          hotel?.openingAvailable && (
            <div className={classes.infoDiv}>
              <Info className={classes.infoIcon} />
              <Typography className={classes.infoText}>
                <span style={{ fontWeight: "800" }}>{getDeliveryType}</span>{" "}
                available until{" "}
                <span style={{ fontWeight: "800" }}>
                  {getLastTime(
                    getTodayDeliveryIntervalSlots.length > 0
                      ? getTodayDeliveryIntervalSlots
                      : getDeliveryIntervalSlots
                  )}
                </span>
              </Typography>
            </div>
          )}
      </div>

      {/* {orderTimeType === "shedulued" && ( */}
      {/* )} */}
      <div className={classes.btnDiv}>
        <Button
          disabled={!formValues?.orderType}
          className={classes.continueBtn}
          onClick={handleClose}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default SelectOrderType;
