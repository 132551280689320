import React from "react";
import useStyles from "./AvatarMenu.style";
import clsx from "clsx";
import { Card, CardContent, Icon, Typography } from "@material-ui/core";

const AvatarItem = ({
  title,
  disabled,
  value,
  selected = false,
  icon,
  isDelete = false,
  handleClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.avatarRoot,
        // selected ? "selected" : null,
        disabled && classes.diabled
      )}
      onClick={() => handleClick(value)}
    >
      <Card className={isDelete ? classes.deleteBtn : classes.card}>
        <div className={classes.iconDiv}>
          <Icon className={classes.icon}>{icon}</Icon>
        </div>
        <CardContent className={classes.textDiv}>
          <Typography className={classes.text} variant="body1">
            {title}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default AvatarItem;
