import { useMemo } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
// Material
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// Components
import ImageView from "../../ImageView/ImageView";
// Icons
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import { FileCopyOutlined } from "@material-ui/icons";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import LocalShippingOutlined from "@material-ui/icons/LocalShippingOutlined.js";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import LocalDiningOutlinedIcon from "@material-ui/icons/LocalDiningOutlined";
import CloseIcon from "@material-ui/icons/Close";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
// Controls
import IconBtn from "./../../Controls/IconButton/IconButton";
// Styles
import useStyles from "./About.style.js";
// Utils
import { convertToMoment } from "../../../utils/TimeUtils";
// Constants
import { ORDER_TYPES, DAYS } from "../../../utils/Constants";
import { Link } from "@material-ui/core";

export default function About({ handleClose = () => {} }) {
  const classes = useStyles();
  const { hotel } = useSelector((state) => state.hotel);
  const hotelAddress = useMemo(() => {
    if (hotel) {
      const address = hotel.address[0];
      return `${address.streetName}, ${address.city}, ${address.country}, ${address.postalCode}`;
    }
    return null;
  }, [hotel]);

  // console.log("hotelllll", hotel)

  const workingHours = useMemo(() => {
    let opening = [],
      pickup = [],
      delivery = [];
    if (hotel) {
      hotel.workingInfo.forEach((w) => {
        const _opening = w.hotelDayInfo
          .filter((i) => i.type === ORDER_TYPES.OPENING)
          .map((i) => i.running_hours);
        const _pickup = w.hotelDayInfo
          .filter((i) => i.type === ORDER_TYPES.PICKUP)
          .map((i) => i.running_hours);
        const _delivery = w.hotelDayInfo
          .filter((i) => i.type === ORDER_TYPES.DELIVERY)
          .map((i) => i.running_hours);
        opening.push({
          day: DAYS[w.day],
          slots: _opening.length > 0 ? _opening[0] : [],
        });
        pickup.push({
          day: DAYS[w.day],
          slots: _pickup.length > 0 ? _pickup[0] : [],
        });
        delivery.push({
          day: DAYS[w.day],
          slots: _delivery.length > 0 ? _delivery[0] : [],
        });
      });
    }
    return {
      opening,
      pickup,
      delivery,
    };
  }, [hotel]);

  // console.log("hotel", hotel);

  const getPaymentMethods = (payment) => {
    const active = payment.filter((pf) => pf?.active);
    return active.map((a, i) => (
      <span style={{ textTransform: "capitalize" }}>{`${a?.type}${
        active.length !== i + 1 ? `, ` : ""
      }`}</span>
    ));
  };

  return (
    <div className={classes.root}>
      <IconBtn
        aria-label="close"
        component="span"
        icon={<CloseIcon />}
        onClick={handleClose}
        className={classes.closeButton}
      />
      <div className={classes.titleSection}>
        <Typography
          className={classes.title}
          variant="h1"
          align="center"
          paragraph
        >
          {hotel?.hotelName}
        </Typography>
        <Divider />
        <Typography
          className={classes.description}
          variant="body1"
          align="center"
          paragraph
        >
          {hotel?.description}
        </Typography>
      </div>
      <div className={classes.bodySection}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <AccessTimeOutlinedIcon className={classes.subTitleIcon} />
                Opening Hours
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <List dense={true} classes={{ root: classes.detailsSection }}>
                {workingHours.opening.map((d, i) => (
                  <ListItem
                    key={i}
                    classes={{ root: classes.workingDayListItem }}
                  >
                    <ListItemText primary={d.day} />
                    <div className={classes.grow} />
                    <div>
                      {d.slots.length === 0 && (
                        <ListItemText
                          primary={`Not available`}
                          classes={{
                            primary: clsx(
                              classes.sectionText2,
                              classes.notAvailable
                            ),
                          }}
                        />
                      )}
                      {d.slots.map((s, si) => (
                        <ListItemText
                          key={si}
                          primary={`${convertToMoment(s.start).format(
                            "HH:mm"
                          )} - ${convertToMoment(s.stop).format("HH:mm")}`}
                          classes={{ primary: classes.sectionText2 }}
                        />
                      ))}
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <StoreOutlinedIcon className={classes.subTitleIcon} />
                Delivery
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <List dense={true} classes={{ root: classes.detailsSection }}>
                {workingHours.delivery.map((d, i) => (
                  <ListItem
                    key={i}
                    classes={{ root: classes.workingDayListItem }}
                  >
                    <ListItemText primary={d.day} />
                    <div className={classes.grow} />
                    <div>
                      {d.slots.length === 0 && (
                        <ListItemText
                          primary={`Not available`}
                          classes={{
                            primary: clsx(
                              classes.sectionText2,
                              classes.notAvailable
                            ),
                          }}
                        />
                      )}
                      {d.slots.map((s, si) => (
                        <ListItemText
                          key={si}
                          primary={`${convertToMoment(s.start).format(
                            "HH:mm"
                          )} - ${convertToMoment(s.stop).format("HH:mm")}`}
                          classes={{ primary: classes.sectionText2 }}
                        />
                      ))}
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <StoreOutlinedIcon className={classes.subTitleIcon} />
                Pickup
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <List dense={true} classes={{ root: classes.detailsSection }}>
                {workingHours.pickup.map((d, i) => (
                  <ListItem
                    key={i}
                    classes={{ root: classes.workingDayListItem }}
                  >
                    <ListItemText primary={d.day} />
                    <div className={classes.grow} />
                    <div>
                      {d.slots.length === 0 && (
                        <ListItemText
                          primary={`Not available`}
                          classes={{
                            primary: clsx(
                              classes.sectionText2,
                              classes.notAvailable
                            ),
                          }}
                        />
                      )}
                      {d.slots.map((s, si) => (
                        <ListItemText
                          key={si}
                          primary={`${convertToMoment(s.start).format(
                            "HH:mm"
                          )} - ${convertToMoment(s.stop).format("HH:mm")}`}
                          classes={{ primary: classes.sectionText2 }}
                        />
                      ))}
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <LocalDiningOutlinedIcon className={classes.subTitleIcon} />
                Cuisines
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <Grid container spacing={1}>
                {hotel.cuisines &&
                  hotel.cuisines.map((c, i) => (
                    <Grid
                      key={i}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      classes={{
                        root: classes.cuisinesItem,
                      }}
                    >
                      <ImageView
                        image={c.image}
                        className={classes.cuisinesItemImage}
                      />
                      <Typography
                        className={classes.cuisinesItemText}
                        variant="caption"
                        paragraph
                      >
                        {c.name}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Grid> */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <MonetizationOnOutlinedIcon className={classes.subTitleIcon} />
                Payment Method
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <div className={classes.paymentDiv}>
                <Typography
                  className={classes.description}
                  variant="subtitle2"
                  paragraph
                >
                  {getPaymentMethods(hotel?.payment)}
                </Typography>
                <Typography
                  className={classes.description}
                  variant="subtitle2"
                  paragraph
                >
                  {hotel?.online_payment_enable && "Credit Card/Debit Card"}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <LocationOnOutlinedIcon className={classes.subTitleIcon} />
                Address
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <Typography
                className={classes.description}
                variant="subtitle2"
                paragraph
              >
                {hotelAddress ? hotelAddress : ""}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <LocalShippingOutlined className={classes.subTitleIcon} />
                Delivery Zones
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <div className={classes.zoneContainer}>
                {/* Table Header */}
                <Grid container spacing={2} className={classes.tableHeader}>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle2"
                      className={classes.tableHeading}
                    >
                      Delivery Zone
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle2"
                      className={classes.tableHeading}
                    >
                      Zone Area
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle2"
                      className={classes.tableHeading}
                    >
                      Delivery Charge
                    </Typography>
                  </Grid>
                </Grid>

                {/* Table Content */}
                {hotel?.deliveryzones?.map((zone) => (
                  <Grid
                    container
                    spacing={2}
                    key={zone?.id}
                    className={classes.zoneRow}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        className={classes.description}
                      >
                        {zone?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        className={classes.description}
                      >
                        {zone?.zone_area}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        className={classes.description}
                      >
                        {zone?.delivery_fee}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <CallOutlinedIcon className={classes.subTitleIcon} />
                Phone
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <Typography
                className={classes.description}
                variant="subtitle2"
                paragraph
              >
                <a href={`tel:${hotel.mobile}`} className={classes.phoneLink}>
                  {hotel.mobile}
                </a>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <LanguageOutlinedIcon className={classes.subTitleIcon} />
                Website
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <Typography
                className={classes.description}
                variant="subtitle2"
                paragraph
              >
                <a
                  href={
                    hotel.website.startsWith("http://") ||
                    hotel.website.startsWith("https://")
                      ? hotel.website
                      : `https://${hotel.website}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.websiteLink}
                >
                  {hotel.website}
                </a>
              </Typography>
            </div>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <FileCopyOutlined className={classes.subTitleIcon} />
                Terms and conditions
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <Typography
                className={classes.description}
                variant="subtitle2"
                paragraph
              >
                {hotel?.terms_condition}
              </Typography>
            </div>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <LanguageOutlinedIcon className={classes.subTitleIcon} />
                Terms of use of service
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <Typography
                className={classes.description}
                variant="subtitle2"
                paragraph
              >
                {hotel?.terms_of_use}
              </Typography>
            </div>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.subTitleSection}>
              <Typography variant="h6" className={classes.subTitle}>
                <LanguageOutlinedIcon className={classes.subTitleIcon} />
                Allergy Information
              </Typography>
            </div>
            <Divider />
            <div className={classes.subSectionContent}>
              <Typography
                className={classes.description}
                variant="subtitle2"
                paragraph
              >
                {hotel?.terms_of_allergy}
              </Typography>
            </div>
          </Grid> */}
        </Grid>
        <div className={classes.footerDiv}>
          <div className={classes.versionCon}>
            <Link
              target="_blank"
              className={classes.footerLinkText}
              rel="noopener noreferrer"
              // href={hotel?.terms_condition}
              href={hotel?.terms_condition}
            >
              Terms and conditions
            </Link>
            <Divider orientation="vertical" variant="middle" flexItem />
          </div>
          <div className={classes.versionCon}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              // href={hotel?.terms_condition}
              className={classes.footerLinkText}
              href={hotel?.terms_of_use}
            >
              Terms of use of service
            </Link>
            <Divider orientation="vertical" variant="middle" flexItem />
          </div>
          <div className={classes.versionCon}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={hotel?.terms_of_allergy}
              className={classes.footerLinkText}
            >
              Allergy Information
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.versionCon}>
        <Typography
          className={classes.textDimmed}
          variant="subtitle2"
          paragraph
        >
          Version: {process.env.REACT_APP_VERSION}
        </Typography>
      </div>
    </div>
  );
}
